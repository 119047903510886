.all-technologies-container{
    padding: 2vw 5vw;
    background: linear-gradient(178.68deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.75) 29.82%, #35374F 94.97%);
    /* height: 450px; */
    
}
.ourtech{
    font-size: 2.5vw;
    margin: 30px 0;
    border-left: 3px solid #35374F;
    padding-left: 10px;
    font-weight: 600;
    color:#35374F;
}
.tech{
    color: #7ed956;
}
.container{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

}
 
.otbox{
    height: 4vw;
    border-radius: 20px;
    margin: 10px;
    background: #FFFFFF;
    flex:0 0 14vw;
    padding: 10px;
    width: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;


}
.techo{
    max-width: 60%;
    height: 50px;

}

@media only screen and (max-width: 800px) {
    .ourtech{
        font-size: 6vw;
    }
    .otbox{
        flex:0 0 20vw;
        padding: 20px;
        border-radius: 4vw;
    }

    .techo{
        height: 10vw;
    }
}