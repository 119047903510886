.mainpage-container1{
    display: flex;
    flex-direction: row;
    padding: 6vw 5vw;
    justify-content: space-between;
   }
   
   .container1-column1{
       width: 50%;
   }
   
   .green-text{
       color: white;
       background: #7ED957;
       display:flex;
        justify-content: center;
        max-width: 25vw;
       border-radius: 25px;
       font-size: 1.5vw;
       padding: 10px 0px 10px 0px;
   }
   
   .big-text {
        margin: 25px 25px 5px 0px;
        font-size: 5vw;
        font-weight: 600;
        color: #35374F;
        text-align: left;
   }
   
   .small-text{
       font-size: 2vw;
       color: #737373;
       margin: 25px 25px 5px 0px;
       text-align: left;
   }
   
   .container1-column2-image{
       display: flex;
       justify-content: center;
       align-items: center;
   }
   
   .container1-column2-image img{
       width: 35vw;
       height: auto;
       max-height: 35vw;
       border-radius: 5%;
   }
   

@media screen and (max-width:750px){
    .mainpage-container1{
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
        height: fit-content;
    }

    .container1-column1{
        width: 100%;
    }

    .green-text{
        max-width: 50vw;
         font-size: 3vw;
         padding: 5px;
        /* padding: 1vw 2vw 1vw 2vw; */
        /* padding-top: 10px;
        margin-top: 30px; */
    }

    .big-text{
        margin: 0px;
        font-size: 12vw;
        padding: 5px 0;
    }

    .small-text{
        margin:3px 25vw 3px 0px;
        font-size: 3.8vw;
    }

    .container1-column2-image img{
        width: 90vw;
        max-height: 400px;
        margin-top: 40px;
    }
}