/* Navbar.css */
header {
    top: 0;
    position: sticky;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px 0px;
    color: #fff;
    /* z-index: 5; */
    box-shadow: 0px 4px 4px 0px #00000026;

}

.logo img {
    margin-left: 4vw;
    width: 18vw;
}

nav {
    margin-right: 4vw;
}

nav ul.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

nav ul.menu li {
    text-decoration: none;
    margin-left: 20px;
    align-items: center;
}

nav ul.menu li a{
    text-decoration: none;
    color: #35374F;
    font-size: 1.3vw;
    font-weight: 450;

}

nav ul.menu li a:hover{
color: #7ED957;
}

.bdr{
    display: none;
    text-decoration: none;
    color: #35374F;
    font-size: 6.5vw;
    font-weight: 450;
}

.bd{
    display: flex;
    justify-content: center;
    background-color: #35374F;
    border-radius: 18px;
    width: 7vw;
    height: fit-content;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.bde{
    padding-top: 0px;
    transition-duration: 1000ms;
}


nav ul.menu li.dropdown:hover .dropdown-content {
    display: block;
}

nav ul.menu li.dropdown .dropdown-content {
    display: none;
    width: fit-content;
    /* top: 80px; */
    position: absolute;
    background-color: #fff;
    padding: 0.5rem;
    padding-top: 15px;
    text-align: start;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000026;
}

nav ul.menu li.dropdown .dropdown-content li{
padding: 5px 10px;
padding-left: 0%;
text-decoration: none;
list-style-type: none;
}

nav ul.menu li.dropdown.show .dropdown-content {
    display: block;
}

nav ul.menu li.dropdown .dropdown-content a {
    display: block;
    color: #35374F;
    text-decoration: none;
}

nav ul.menu li.dropdown .dropdown-content a:hover {
color: #7ED957;
}

.menu-toggle {
    display: none;
    cursor: pointer;
}

.menu-toggle span {
    display: block;
    width: 6vw;
    height: 3px;
    background-color:#7ED957;
    margin-bottom: 3px;
}

.contact{
    width: 37vw;
    margin: 1vw 0vw;
    height: 100px;
    background: #7ED957;
    display: flex;
    justify-content: flex-start;
    border-radius: 10px;
}

.contactm{
    display: none;
    left: -10px;
    
    background: #7ED957;
    flex-direction: column;
    border-radius: 10px;
}

.contact_i{
    padding: 1vw;
    margin-left: 2vw;
}

.hh2{
    margin: 0%;
    padding-left: 0.6vw;
    border-left: 3px solid #fff;
}

.pp{
    margin: 0%;
    padding-left: 1vw;
    text-decoration: none;
}

.pp a{
    text-decoration: none;
    padding-top: 5px;
    color: #fff;
}

.bde:hover .contact{
    display: flex;
}

@media screen and (max-width: 600px) {
    .menu-toggle {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .logo img {
        margin-left: 20px;
        width: 40vw;
    }
    
    nav {
        margin-right: 20px;
    }

    nav ul.menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        background-color: #fff;
        /* padding: 1rem; */
        font-size: 24px;
    }

    nav ul.menu li{
        padding: 10px 0px;
        margin-left: 0;
    }

    nav ul.menu li a{
        font-size: 22px;
    }
    
    .bdr{
        display: block;
        text-align: center;
        font-size: 5vw;
        margin-bottom: 40px;

    }

    .bd{
        display: none;
    }

    nav ul.menu li.dropdown .dropdown-content {
        width: 100%;
        text-align: center;
        left: -10px;
    }

    /* nav ul.menu li.dropdown .dropdown-content li {
        padding: 0;
    } */

    nav ul.menu.show {
        display: flex;
    }

    .contactm{
        display: flex;
        text-align: center;
    }

    .hh1{
        text-decoration: none;
        padding-bottom: 2px;
        color:#35374F;
        font-size: 5.4vw;
        padding-left: 0;
    }

    .hh2{
        text-decoration: underline;
        color: #7ED957;
        font-size: 5vw;
        padding-left: 0;
    }

    .pp{
        color: gray;
        padding: 0;
    }
}
