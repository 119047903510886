.component h1{
    color:  #35374F;
    font-style:Monospace;
}
.heading{
    text-align: start;
    font-size: 2.5vh;
    margin-left: 5vw;
}
.boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 5vw;


}
.box{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: rgb(248, 248, 248);
    width: 25vw;
    height: 205px;
    padding: 5px 25px;
    margin-bottom: 3vw;
    box-shadow: 2px 8px 8px -4px rgba(0,0,0,0.45);
    border-radius: 25px;

}
.box img {
    width: 3vw;
    margin-top: 15px;

}
.box h1{
    text-align: start;
    margin: 5px 0;
    font-size: 2.8vh;
}
.box p{
    text-align: start;
    color:  #0D6616;
    font-size: 1.8vh;
}
@media only screen and (max-width: 800px) {
    .boxes{
    justify-content: center ;
}
 .box{
    text-align: center;
    width: 90vh;
    margin-bottom: 30px;
    height: fit-content;
 }
 .box img{ 
    justify-content: center;
    width: 5vh;
 }
 .heading {
    font-size: 4vw;
 }
}