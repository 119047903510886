.footer-box{
    background-color:rgba(53, 55, 79, 1);
    
}




.footer-links{
    color: rgba(249, 249, 249, 1);
    display:flex;
    font-weight:500;
    font-size: 1vw;
    justify-content: space-between;
    width: 89%;
    padding:20px 5vw;
    text-align: start;
   
}
.links{
    display: flex;
    align-items: center;
}

.footer-first div{
    padding-left: 0%;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    
}


.footer-second div{
    padding-left: 0%;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.footer-third img{
    display: block;
    width:5vw;
}

.footer-line{
 
    margin:0px 5vw;
}


.footer-text{
    text-align: start;
    color: rgba(249, 249, 249, 1);
    padding:0px 5vw;
    font-size: 1.2vw;
    font-weight: 600;
}

.footer-link{
    color:rgba(96, 214, 105, 1);
    
}

.footer-link h1{
    margin-top:-1%;
    font-weight:600;
}

.footer-last{
    background-color:rgba(96, 214, 105, 1) ;
    /* height:30px; */
    text-align: center;
    font-weight:500;

    font-size: 1vw;
    padding:5px 0px;
   
}

@media only screen and (max-width: 800px) {
    .footer-links{
        font-size: 3vw;
    }

    .footer-third img{
        width: 15vw;
    }

    .footer-text{
        font-size: 3vw;
    }

    .footer-last{
        font-size: 2vw;
    }
}