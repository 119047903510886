.Teammates-persons {
    display: flex;
    flex-direction: row;
    /* margin-left: 3vw; */
}

.Teammates-image-div {
    margin: 1vh 1vh 0 1vh;
    
}

.Teammates-image-div .im{
    height: 4vw;
    width: 4vw;
    border-radius: 100%;
    border: 2.5px solid #585b55 ;
    transition-duration: 300ms;
}

.Teammates-image-div:hover .im {
    height: 15vw;
    width: 15vw;
}


.Person-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.Person-name {
    font-size: 1.2vw;
    margin: 0;
}

.Person_designation{
    font-size: 1vw;
    text-align: left;
    font-style: italic;
    margin: 3px 0 0 0;
    color: rgba(102, 102, 102, 0.959);
}

@media screen and (max-width:750px) {
    .Teammates-image-div {
        margin: 1vh 3vh 0 3vh;
    }

    .Teammates-image-div .im{
        height: 12vw;
        width: 12vw;
    }

    .Person-text{
        margin: 0;
    }

    .Person-name{
        font-size: 3.5vw;
    }

    .Person_designation{
        font-size: 3vw;
    }
}