.service1-box{
    background: rgba(70, 63, 94, 1);
    color:rgba(255, 255, 255, 1);
    display: flex;
    justify-content: space-between;
    margin:30px 5vw;
    height: fit-content;
    border-radius:40px;
    align-items: center;
}


.service1-text{
 padding:20px;
 padding-left:70px;
 font-size:1.3vw;
}

.service1-text h1{
   font-weight:600;
   margin-top:2px;
}

.service1-img{
    padding:40px;
}

.service1-logo{
    border-radius: 30px;
    width:22vw;
    height:30vh;
}


/* Service2 box */
.service2-box{
    background: rgba(126, 217, 86, 1);
    color: rgba(53, 55, 79, 1);
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin:30px 5vw;
    height: fit-content;
    border-radius:40px;
}


.service2-text{
 padding:30px;
 padding-left:70px;
 font-size:1.3vw;
}

.service2-text h1{
   font-weight:600;
   margin-top:2px;
}

.service2-img{
     padding:40px;
}

.service2-logo{
    border-radius: 30px;
    width:22vw;
    height:30vh;
}


@media screen and (max-width:750px){
    .service1-box{
        flex-direction: column-reverse;
        justify-content:space-evenly;
        border-radius:20px;
     }
     .service1-text{
        font-size: 2.5vw;
        padding:20px;
        text-align: center;
     }

     .service1-text h1{
        margin-top:0px;
     }
     .service1-img{
     padding:25px 0 0 0;
     
     }
    .service1-logo{
        width:70vw;
        height:40vw;
        border-radius: 10px;
    }


    /* Service2 box */
    .service2-box{
        flex-direction: column-reverse;
        justify-content:space-evenly;
        border-radius: 20px;
     }
     .service2-text{
        font-size: 2.5vw;
        padding-top:0px;
        text-align: center;
        padding:20px;
     }

     .service2-text h1{
        margin-top:0px;
     }
     .service2-img{
        padding:25px 0 0 0;
     }
     .service2-logo{
        width:70vw;
        height:40vw;
        border-radius: 10px;
    }
   

}