.Teammates-container{
    display: flex;
    flex-direction: row;
    padding: 0px 4vw;
}

.Teammates-description {
    width: 110vw;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.Teammates-green-text {
    color: #7ED957;
    font-size: 4.5vw;
    text-align: left;
    margin: 100px 0 0 0;
}

.Teammates-para {
    color: grey;
    font-weight: 600;
    text-align: left;
    font-size: 1.5vw;
    line-height: 2.5vw;
}

.Teammates-button-div {
    margin-left: 0px;
}

.Teammates-button {
    background-color: #7ED957;
    border: none;
    border-radius: 3px;
    font-size: 1.2vw;
    padding: 14px 28px;
    color:aliceblue;
    cursor: pointer;
    transition-duration: 0.4s;
    border-width: 2px;
    border-color: aliceblue;
    margin-bottom: 10vw;
}

.Teammates-button:hover {
    background-color: rgb(46, 179, 106);
    color:azure;
    border:2px solid #7ED957;
}

.Teammates-people-list{
    width: 40vw;
    margin: 5vw 2vw;
    margin-left: 5vw;
}

.Teammates-hrline {
    border-top: 2px solid grey;
    width: 35vw;
}

@media screen and (max-width:750px) {
    .Teammates-container{
        flex-direction: column;
    }

    .Teammates-description{
        width: 80vw;
        margin: auto;
    }
    
    .Teammates-green-text {
        font-size: 7.5vw;
        margin-top: 10vw ;
    }

    .Teammates-para {
        font-size: 4vw;
        line-height: 30px;
    }
    
    .Teammates-button {
        padding: 10px 18px;
        font-size: 4vw;
    }

    .Teammates-people-list{
        margin: 20px 0 0 0 ;
        width: 92vw;
        
    }

    .Teammates-hrline {
        border-top: 2px solid grey;
        width: 80vw;
    }
}