.AboutContainer{
    width: 100%;
    background: #35374F;
    padding: 3vw 0;
}

.AboutTitle{
    padding: 2vw 5vw;
    padding-bottom: 0%;
    font-size: 3vw;
    font-weight: 700;
    color: #ffffffc9;
}

.AboutBox1{
    padding: 20px 5vw;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}

.AboutBox2{
    padding: 20px 5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}

.AboutText{
    font-size: 1.5vw;
    width: 60%;
    height: 25vw;
}

.AboutText h1{
    width: 74%;
    font-size: 3vw;
}

.AboutText h2{
    margin: 5px 0;
    font-size:1.7vw;
}

.AboutText p{
    margin: 5px 0;
    font-size: 1.4vw;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);

}

.AboutImg1 img{
    width: 25vw;
    height: 30vw;
}

.AboutImg2 img{
    width: 30vw;
    height: 20vw;
    align-items: start;
}

@media only screen and (max-width: 600px) {
    .AboutTitle{
        font-size: 7vw;
        text-align: center;
    }
    .AboutBox1{
        flex-direction: column;
    }

    .AboutBox2{
        flex-direction:column-reverse;
    }

    .AboutText{
        width: 100%;
        height:auto;
        text-align: center;
    }

    .AboutText h1{
        font-size: 5.5vw;
        width: 100%;
    }

    .AboutText h2{
        font-size: 4vw;
        padding-top: 15px;
    }

    .AboutText p{
        font-size: 3vw;
    }

    .AboutImg1 img{
        width: 60vw;
        height: 80vw;
    }

    .AboutImg2 img{
        width: 70vw;
        height: 50vw;
        padding-top: 20px;
    }
}