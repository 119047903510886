.p{
    background: rgba(53, 55, 79, 1);
    color:aliceblue;
    padding: 3vw 5vw;
}
.project_main_box{
    background: rgba(53, 55, 79, 1);
    /* width:90%; */
    color:aliceblue;
    padding: 2vw;
}

.project_title{
    display: flex;
}

.project_title h1{
    font-weight: 600;
    border-left: 3px solid #fff;
    padding-left: 10px;
}

.green{
    color: rgba(126, 217, 86, 1);
}


.project_line{
    /* margin:5px;
    margin-right:80vw; */
    
    border:2px solid rgba(126, 217, 86, 1);
}

.project_image{
    display:flex;
    width: 100%;
    justify-content: space-evenly;
}

.project_box h1{
    font-weight: 400;
    font-size: 2vw;
}
.project_box a img{
    padding:5px 10px;
    padding-top:20px;
    width: 7vw;
    height: auto;
}